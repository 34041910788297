export const WIDGET_CATEGORY_ID_ENUMS = {
  CONTENT: 1,
  CALENDAR: 2,
  NEWS: 3,
  LOCATION: 4,
  INTEGRATION: 5,
  FORMBUILDER: 6,
  ENGAGEMENT: 7,
  MYWASTE: 8,
  RECREATION: 9
}