<template>
  <div class="errorHandler">
    <div v-if="error" v-html="error.data && error.data.dashboardTxt" class="theme-font-regular font-16 error"></div>

    <template v-if="error.data && error.data.isAddressMissing">
      <p class="font-16">
        {{ translateLanguage('Txt_Please_add') }}
        <router-link class="theme-font-bold" :to="`/${selectedLanguage}/account/property`">{{ translateLanguage('Txt_Your_address') }}</router-link>
        {{ translateLanguage('Txt_To_see_more_info') }}
      </p>
    </template>

    <template v-else>
      <p class="theme-font-regular font-16"></p>
      <div><p v-html="errorMessage" class="errorMessage"></p></div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { generateContactUsMessage } from '../../utilities/utilities'
export default {
  name: 'ErrorHandler',
  props: ['error', 'contactUsUrl'],
  computed: {
    errorMessage() {
      return this.error.isContact
        ? `${this.translateLanguage(this.error.message)} ${generateContactUsMessage(this.error.isContact, this.contactUsUrl)}`
        : this.translateLanguage(this.error.message)
    },
    ...mapState({
      selectedLanguage: (state) => state.common.selectedLanguage,
    }),
  },
}
</script>
<style lang="scss" scoped></style>
