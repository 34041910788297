import { useTranslateLanguage } from '../composables/useTranslateLanguage'
import { WIDGET_CATEGORY_ID_ENUMS } from './widget-constants'

export const generateContactUsMessage = (useContactUrl, contactUrl) => {
  const { translateLanguage } = useTranslateLanguage()
  const link = useContactUrl
    ? ` <strong><a href=${contactUrl} target= '_blank'>${translateLanguage.value?.Txt_Contact_us} </a></strong><span class="sr-only"> - opens in new tab</span> `
    : translateLanguage.value?.Txt_Contact_us

  return `${translateLanguage.value?.Txt_Problem_persists} ${link} ${translateLanguage.value?.Txt_To_resolve_issue}`
}

export const getContrastColor = (value) => {
  if (!/^#[0-9A-Fa-f]{6}$/i.test(value)) return ''

  const hex = value.substring(1)
  const hexToR = (h) => parseInt(hex.substring(0, 2), 16)
  const hexToG = (h) => parseInt(hex.substring(2, 4), 16)
  const hexToB = (h) => parseInt(hex.substring(4, 6), 16)

  const threshold = 130 /* about half of 256. Lower threshold equals more dark text on dark background  */
  const hRed = hexToR(hex)
  const hGreen = hexToG(hex)
  const hBlue = hexToB(hex)

  const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000

  return cBrightness > threshold ? '#000000' : '#ffffff'
}

export const setCssVariable = (name, value, target = document.documentElement) => {
  if (!name || !value) {
    return
  }

  target.style.setProperty(name, value)
}

export const getWidgetName = (key) => {
  let widgetName = '';
  switch (key) {
    case WIDGET_CATEGORY_ID_ENUMS.CALENDAR: 
      widgetName = 'Calendar'
      break
    case WIDGET_CATEGORY_ID_ENUMS.CONTENT:
      widgetName = 'Content'
      break
    case WIDGET_CATEGORY_ID_ENUMS.ENGAGEMENT:
      widgetName = 'Engagement'
      break
    case WIDGET_CATEGORY_ID_ENUMS.FORMBUILDER:
      widgetName = 'Form Builder'
      break
    case WIDGET_CATEGORY_ID_ENUMS.INTEGRATION:
      widgetName = 'Integration'
      break
    case WIDGET_CATEGORY_ID_ENUMS.LOCATION:
      widgetName = 'Location'
      break
    case WIDGET_CATEGORY_ID_ENUMS.MYWASTE:
      widgetName = 'My Waste'
      break
    case WIDGET_CATEGORY_ID_ENUMS.NEWS:
      widgetName = 'News'
      break
    case WIDGET_CATEGORY_ID_ENUMS.RECREATION:
      widgetName = 'Recreation'
      break
  }

  return widgetName
} 
